// PRIMARY COLOURS

$dark-purple: #3c2a52;
$mid-purple: #763461;
$light-purple: #8e667f;
$sand: #EAB052;
$peach: #dc9c6d;


// SECONDARY COLOURS

$mauve: #ad8c88;
$black: #000;
$white: #ffffff;

// BREAKPOINTS

$tablet-breakpoint:768px;
$desktop-breakpoint: 1280px;