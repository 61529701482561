@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.contact {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-bottom: 2rem;

    @include tablet {
        flex-direction: row;
        justify-content: space-evenly;
    }

    &__content {
        @include tablet {
            align-self: center;
        }
    }

    &__heading {
        margin-top: 0rem;
        color: $mid-purple;
        text-align: center;
        font-family: 'hussar';

        // @include tablet {
        //     font-size: 3rem;
        // }
    }

    &__copy {
        color: $mauve;
        text-align: center;
        font-family: 'copy';
    }

    &__link-container {
        display: flex;
        align-content: center;
        border-radius: .3125rem;
        margin: .5rem;
        padding: .25rem;
    }

    &__link-container:hover {
        background-color: $light-purple;
    }

    &__container--2 {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    &__icon {
        height: 2rem;
    }

    &__link-name {
        display: none;
        margin: 0rem;
    }

    &__form {
        margin-top: 1rem;
        background-color: $peach;
        padding: 1rem;
        border-radius: .625rem;
        font-family: 'hussarOther';

        @include tablet {
            width: 25rem;
        }
    }

    &__form-input-container {
        margin-bottom: .5rem;
    }

    &__form-heading {
        margin: 0rem 0rem .25rem 0rem;
        color: $mid-purple
    }

    &__form-input {
        border-radius: .3125rem;
        border: none;
        height: 1.5rem;
        width: 100%;
        background-color: $mauve;
        padding-left: .5rem;
        margin-bottom: .5rem;
    }

    &__form-input--message {
        border-radius: .3125rem;
        border: none;
        height: 5rem;
        width: 100%;
        background-color: $mauve;
        padding: .5rem 0rem 0rem .5rem;
        margin-bottom: .5rem;
    }

    button {
        position: relative;
        display: inline-block;
        cursor: pointer;
        outline: none;
        border: 0;
        vertical-align: middle;
        text-decoration: none;
        background: transparent;
        padding: 0;
        font-size: inherit;
        font-family: inherit;

        &.email-button {
            width: 12rem;
            height: auto;

            .circle {
                @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
                position: relative;
                display: block;
                margin: 0;
                width: 3rem;
                height: 3rem;
                background: $dark-purple;
                border-radius: 1.625rem;

                .icon {
                    @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    background: $white;

                    &.arrow {
                        @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
                        left: 0.625rem;
                        width: 1.125rem;
                        height: 0.125rem;
                        background: none;

                        &::before {
                            position: absolute;
                            content: '';
                            top: -0.25rem;
                            right: 0.0625rem;
                            width: 0.625rem;
                            height: 0.625rem;
                            border-top: 0.125rem solid #fff;
                            border-right: 0.125rem solid #fff;
                            transform: rotate(45deg);
                        }
                    }
                }
            }

            .button-text {
                @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 0.75rem 0;
                margin: 0 0 0 1.85rem;
                color: $black;
                font-weight: 700;
                line-height: 1.6;
                text-align: center;
                text-transform: uppercase;
                color: $mid-purple;
            }
        }

        &:hover {
            .circle {
                width: 100%;

                .icon {
                    &.arrow {
                        background: $white;
                        transform: translate(1rem, 0);
                    }
                }
            }

            .button-text {
                color: $white;
            }
        }
    }
}