@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.svg {
    width: 92vw;
    height: 3.75rem;

    @include tablet {
        width: 90vw;
        height: 12vw;
    }
}

// .path:hover {
//     fill: $sand;
//     fill-opacity: 0;
//     stroke: $sand;
//     // stroke-width: 2;
//     stroke-linecap: round;

//     stroke-dasharray: 860;
//     stroke-dashoffset: 860;
//     animation: draw 8s linear forwards;
    
// }

// @keyframes draw {
//     to {
//         stroke-dashoffset: 0;
//     }
// }

// @-webkit-keyframes draw {
//     to {
//         stroke-dashoffset: 0;
//     }
// }

// @-moz-keyframes draw {
//     to {
//         stroke-dashoffset: 0;
//     }
// }

// @-o-keyframes draw {
//     to {
//         stroke-dashoffset: 0;
//     }
// }

.otherPath {
    fill: $sand;
    fill-opacity: 0;
    stroke: $sand;
    // stroke-width: 2;
    stroke-linecap: round;

    stroke-dasharray: 860;
    stroke-dashoffset: 860;
    animation: draw 8s linear forwards;
    
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}

@-moz-keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}

@-o-keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}