@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;


.header {
    position: sticky;


    background-color: $black;

    &__image-banner--1 {
        height: 4.25rem;
        background-image: url(../../assets/images/pexels-sergio-andrés-soto-13329032.jpg);
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__image-banner--2 {
        height: 4.25rem;
        background-image: url(../../assets/images/pexels-sergio-andrés-soto-13329032.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: -3.75rem;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .5rem 1rem .5rem 1rem;
    }

    &__subcontainer--1 {
        display: flex;
        align-items: center;
    }

    &__logo {
        height: 5rem;

        @include tablet {
            height: 4rem;
        }

        @include desktop {
            height: 5rem;
        }
    }

    &__subcontainer--2 {

        @include tablet {
            width: 50%;
            display: flex;
            justify-content: space-around;
        }
    }

    &__menu-button {
        height: 2rem;

        @include tablet {
            display: none;
        }
    }

    &__link {
        display: none;
        font-family: 'hussarOther';
        position: relative;
        text-decoration: none;
        padding-bottom: .25rem;

        @include tablet {
            font-size: .75rem;
            text-decoration: none;
            color: $sand;
            display: inline;
        }

        @include desktop {
            font-size: 1.25rem;
        }
    }

    &__link::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        border-radius: 4px;
        background-color: $sand;
        bottom: 0;
        left: 0;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform .3s ease-in-out;
    }

    &__link:hover::before {
        transform-origin: left;
        transform: scaleX(1);
    }
}

.modal {
    background-color: $black;
    width: 50%;
    height: 100%;
}