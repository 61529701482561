@use './variables' as *;


@mixin tablet {
    @media (min-width: $tablet-breakpoint){
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop-breakpoint){
        @content;
    }
}

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65,0,.076,1)) {
    transition: $property $duration $ease;
  }