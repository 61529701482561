@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.footer {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    background-image: url(../../assets/images/pexels-sergio-andrés-soto-13329032.jpg);
    background-size: cover;
    padding: 1rem;

    @include tablet {
        flex-direction: row;
        justify-content: space-between;
    }

    &__container {
        background-color: rgba($color: #8e667f, $alpha: .5);
        padding: .5rem;
        border-radius: .625rem;
    }

    &__heading {
        font-size: 1.125rem;
        color: $dark-purple;
        font-family: 'hussarOther';
        margin-bottom: .5rem;
    }

    &__copy {
        font-family: 'hussarOther';
        color: $sand;
        font-size: .75rem;
        margin-top: 0rem;
    }

    &__copyright {
        font-family: 'hussarOther';
        color: $sand;
        font-size: .5rem;
    }

    &__icon {
        height: 2rem;
        margin-right: .5rem;
    }
}