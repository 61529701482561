@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.about {
    display: flex;
    margin-bottom: 2rem;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: 2px solid $light-purple;

    &__container {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        // @include desktop {
        //     align-items: flex-start;
        // }
    }

    &__intro-copy {
        color: $light-purple;
        margin: 0rem;
        font-family: 'bulgatti';
        font-size: 3vw;
    }

    &__heading {
        color: $sand;
        margin-top: 0rem;
    }

    &__image-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include tablet {
            flex-direction: row;
            align-items: center;
        }

        @include desktop {
            padding: 0rem 3rem 0rem 3rem;
        }
    }

    &__copy {
        margin-top: 0rem;
        color: $mauve;
        font-family: 'copy';
        text-align: justify;
        line-height: 1.875rem;
        font-size: .875rem;

        @include tablet {
            align-self: flex-start;
        }

        @include desktop {
            min-width: 30%;
            max-width: 50%;
        }
    }

    &__avatar {
        // z-index: -1;
        height: 10rem;
        width: 10rem;
        margin: auto;
        // shape-outside: circle();
        // float: right;

        @include tablet {
            align-self: flex-start;
            height: 12rem;
            width: 12rem;
            margin-left: 1rem;
        }

        @include desktop {
            margin-left: 2rem;
            height: 16rem;
            width: 16rem;
        }
    }
}