@font-face{
    font-family: 'hussar';
    src:url('../assets//fonts/HussarFont/HussarEkologiczne1-4Mj6.otf') format('truetype');
    font-weight: 400;
}

@font-face{
    font-family: 'hussarOblique';
    src:url('../assets//fonts/HussarFont/HussarEkologiczne1Oblique-0B7o.otf') format('truetype');
    font-weight: 400;
}

@font-face{
    font-family: 'hussarOther';
    src:url('../assets/fonts/HussarFont/HussarEkologiczne2-eO3g.otf') format('truetype');
    font-weight: 400;
}



@font-face{
    font-family: 'bulgatti';
    src:url('../assets//fonts/BulgattiFont/Bulgatti-xgMV.ttf') format('truetype');
    font-weight: 400;
}

@font-face{
    font-family: 'copy';
    src: url('../assets/fonts/josefin/JosefinSansRegular-x3LYV.ttf') format('truetype');
    font-weight: 400;
}