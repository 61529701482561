@use '../../styles/fonts' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.sidebar {
    display: flex;
    justify-content: center;
    margin: 1rem 0rem 1rem 0rem;
    padding: .5rem;
    background-color: $dark-purple;
    width: 6.75rem;
    height: 2rem;
    border-radius: 1rem;
    transition: background-color .5s ease-out 100ms ;


    &__icon {
        height: 2rem;
        margin-right: .5rem;
    }

    &__icon--other {
        height: 2rem;
    }
}

.sidebar:hover {
    background-color: $light-purple;
}